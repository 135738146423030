<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <component :is="containerComponent" class="image-container" :class="['legend-' + block.legendPosition, 'legend-' + block.legendTheme]" :style="containerStyle">
            <cms-component-image comp-id="image" :component="block.image" v-bind="componentProps"/>
            <div v-if="block.legend.active" class="legend" :style="legendStyle">
                <cms-component-text comp-id="legend" :component="block.legend" v-bind="componentProps"/>
            </div>
        </component>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentImage from "@/components/editor/rendering/components/cms-component-image.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";

// cms-block-image #hot-reload-debug
export default {
    name: `cms-block-image`,
    components: {CmsComponentText, CmsComponentImage, CmsBlock},
    extends: CmsBlock,
    props: {
        card: {type: Boolean}
    },
    computed: {
        containerComponent() {
            return this.card ? `b-card` : `div`;
        },
        containerStyle() {
            return {
                borderRadius: this.block.image.borderRadius
            }
        },
        legendStyle() {
            return {
                padding: this.block.legendPadding
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.image-container {
    position: relative;
    width: 100%;

    .legend {
        position: absolute;
        z-index: 1;
        color: var(--body-color);
        padding: 10px 20px;
        margin: 0;
        width: 100%;
        border-radius: inherit;

        .cms-component {
            margin: 0;
            position: relative;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0.75;
        }
    }

    &.legend-light {
        .legend {
            color: var(--darkest-color);

            &:before {
                background-color: var(--lightest-color);
            }
        }
    }

    &.legend-dark {
        .legend {
            color: var(--lightest-color);

            &:before {
                background-color: var(--darkest-color);
            }
        }
    }

    &.legend-top {
        .legend {
            top: 0;

            &:before {
                border-top-left-radius: inherit;
                border-top-right-radius: inherit;
            }
        }
    }

    &.legend-bottom {
        .legend {
            bottom: 0;

            &:before {
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;
            }
        }
    }

    &.legend-bottom {
        .legend {
            bottom: 0;

            &:before {
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;
            }
        }
    }

    &.legend-left {
        .legend {
            top: 0;
            left: 0;
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;

            &:before {
                border-top-left-radius: inherit;
                border-bottom-left-radius: inherit;
            }
        }
    }

    &.legend-right {
        .legend {
            top: 0;
            right: 0;
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;

            &:before {
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;
            }
        }
    }
}
</style>
